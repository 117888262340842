<template>
  <ColorBox
    v-if="showColorBox"
    :language="language"
    :cdn-url="cdnUrl"
    :color-config="colorConfig"
    :color-list="colorList"
    :main-sale-attribute="mainSaleAttribute"
    :color-goods-desc="colorGoodsDesc"
    :show-large-image="showLargeImage"
    :beauty-findation-abt="beautyFindationAbt"
    :show-find-my-shade-entry="showFindMyShadeEntry"
    :is-show-attr-new-icon="isShowAttrNewIcon"
    :show-evolushein="showEvolushein"
    :real-time-bff-ready="realTimeBffReady"
    :current-color-info="currentColorInfo"
    @switch-color="handleSwitchColor"
  />
</template>

<script setup>
import { defineComponent, defineProps, computed, onMounted } from 'vue'

import ColorBox from './components/ColorBox/index.vue'

import { useMapGetters, useMapState } from '@detail/store/mapHook'
import { nextTick } from 'async'

defineComponent({
  name: 'ColorV3',
  components: {
    ColorBox,
  },
})

const props = defineProps({
  cdnUrl: {
    type: String,
    default: '',
  },
  pageComponents: {
    type: Object,
    default: () => ({}),
  },
  showAttrImgPopover: {
    type: Boolean,
    default: false
  },
})

const emits = defineEmits([
  'switchColor',
  'currentSelectedColor',
  'handleData',
  'colorMounted',
])

const { realTimeBffReady, showColorsTag } = useMapState('', ['realTimeBffReady', 'showColorsTag'])
const colorType = computed(() => {
  if(mainSaleAttrShowMode.value === 1) return 'text'
  return props.pageComponents?.colorConf?.colorItemType
})

// 配置类相关信息
const colorConfig = computed(() => {
  if (props.pageComponents?.colorConf) {
    return {
      ...props.pageComponents.colorConf,
      colorType: colorType.value,
      imgField: colorType.value === 'block' ? 'goods_thumb' : 'color_image',
      showAttrImgPopover: props.showAttrImgPopover,
      showColorsTag: !!showColorsTag?.value,
    }
  }
  return {
    colorType: colorType.value || 'radio',
    imgField: colorType.value === 'block' ? 'goods_thumb' : 'color_image',
    showAttrImgPopover: props.showAttrImgPopover,
    showColorsTag: !!showColorsTag?.value,
  }
})

const {
  language,
  showColorBox,
  colorList,
  mainSaleAttrShowMode,
  mainSaleAttribute,
  colorGoodsDesc,
  showLargeImage,
  beautyFindationAbt,
  showFindMyShadeEntry,
  isShowAttrNewIcon,
  showEvolushein,
  currentColorInfo
} = useMapGetters('Color', [
  'mainSaleAttrShowMode',
  'mainSaleAttribute',
  'colorList',
  'showColorBox',
  'language',
  'colorGoodsDesc',
  'showLargeImage',
  'beautyFindationAbt',
  'showFindMyShadeEntry',
  'isShowAttrNewIcon',
  'showEvolushein',
  'currentColorInfo'
])

// 事件
const handleSwitchColor = color => {
  emits('switchColor', color)

  nextTick(() => {
    const currentSelectedColor = colorList.value.find(item => item.isCurrentGoods)
    emits('currentSelectedColor', currentSelectedColor)
  })
}

// 为满足之前商详页的数据流，暂时保留 后续数据流优化后可以去除todo:
onMounted(() => {
  emits('handleData', colorList.value)
  const currentSelectedColor = colorList.value.find(item => item.isCurrentGoods)
  emits('currentSelectedColor', currentSelectedColor)
})
</script>
