
export const getHitFitTypeKey = ({ productIntroData }) => {
  if (!productIntroData?.detail) return
  const multiPartProductDetails = productIntroData.detail
    .isMultiPartProduct
    ? (productIntroData.detail.productDetails || []).concat(
      productIntroData.detail.multiPartInfo.reduce(function (
        prev,
        item
      ) {
        prev.push(...(item.attributeList || []))
        return prev
      },
      [])
    )
    : productIntroData.detail.productDetails || []
  const find = multiPartProductDetails.find(
    (i) => i.attr_name_en === 'Fit Type'
  )
  const findIt = find && find['attr_value_en']
  return findIt || ''
}


export function getFitTypeList({ language, key }) {
  return {
    name: language.SHEIN_KEY_PC_33201 || 'Fit Type',
    key,
    list: [
      {
        key: 'Skinny',
        value: language.SHEIN_KEY_PC_33202 || 'Skinny',
      },
      {
        key: 'Slim Fit',
        value: '',
      },
      {
        key: 'Regular Fit',
        value: language.SHEIN_KEY_PC_33203 || 'Regular',
      },
      {
        key: 'Loose',
        value: '',
      },
      {
        key: 'Oversized',
        value: language.SHEIN_KEY_PC_33204 || 'Oversized',
      },
    ]
  }
}

export function getThicknessList({ language = {}, tsp = {} }) {
  const tspValue = tsp['5883']
  if (!tspValue) return

  return {
    name: language.SHEIN_KEY_PC_33197 || 'Thickness',
    key: tspValue,
    list: [
      {
        key: 'thin',
        value: language.SHEIN_KEY_PC_33198 || 'Thin',
      },
      {
        key: 'medium',
        value: language.SHEIN_KEY_PC_33199 || 'Medium',
      },
      {
        key: 'thick',
        value: language.SHEIN_KEY_PC_33200 || 'Thick',
      },
    ]
  }
}

export function getFeatureList({ productDetails = [] }) {
  if (!productDetails?.length) return

  let attr_name = ''
  const list = []
  // 相同类型数据进行合并
  productDetails.forEach(item => {
    if (Number(item.attr_value_id) !== 0 && Number(item.attr_select) !== 0 && +item.attr_id == 217) {
      /**
         * 仅展示以下特征卖点
            三防(1136539)
            防风(1110)
            防水(1717)
            防水(1970)
            保暖(1000226)
            保暖(1004655)
            保暖透气(1003420)
            透气（功能性）(1001863)
            防紫外线（1001867）
            速干(1001866)
            抗菌(1000373)
            抑菌(1001872)
            防臭(1001873)
            防静电(1001876) 
            凉感(1001877)
            吸湿排汗（1166911）
            吸汗(1001862)
         */
      const HIT_IDS = [1136539, 1110, 1717, 1970, 1000226, 1004655, 1003420, 1001863, 1001867, 1001866, 1000373, 1001872, 1001873, 1001876, 1001877, 1166911, 1001862]
      if (!HIT_IDS.includes(+item.attr_value_id)) return
      attr_name = item.attr_name
      list.push(item.attr_value)
    }
  })

  if (!list.length) return
  return {
    attr_name,
    attr_value: list.join(', '),
    attr_value_id: 217,
    list,
  }
}
