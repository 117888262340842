<template>
  <div
    class="store-quality-tags"
    @click="goToStore"
  >
    <swiper-container
      ref="storeQualitySwiper"
      init="false"
      destroy-on-disconnected="false"
      class="s-swiper-wrapper store-quality-tags__swiper"
    >
      <swiper-slide
        v-for="(item, key) in storeQualityList"
        :key="key"
        class="s-swiper-slide swiper-slide store-quality-tags__slide"
      >
        <div class="store-quality-tags__item">
          <img
            :src="item.icon"
            class="tags-icon"
          />
          <span class="tags-text">{{ item.multiLanguageValue }}</span>
        </div>
      </swiper-slide>
      <swiper-slide  
        class="s-swiper-slide swiper-slide store-quality-tags__slide"
      >
        <div class="store-quality-tags__empty"></div>
      </swiper-slide>
    </swiper-container>
    <div 
      v-if="!isSwiperContentEnd"
      class="store-quality-tags__thumb"
      @click.stop
    >
      <div class="thumb-shadow"></div>
      <Icon 
        class="thumb-icon j-storeswiper-prev"
        :class="{'disabled': isSwiperBeginning}"
        name="sui_icon_more_left_12px_1" 
        color="#000"  
        size="12px"
        :is-responsive-name="true"
        :is-rotate="GB_cssRight"
      />
      <Icon 
        class="thumb-icon j-storeswiper-next"
        :class="{'disabled': isSwiperEnd}"
        name="sui_icon_more_right_12px_1" 
        color="#000"  
        size="12px"
        :is-rotate="GB_cssRight"
      />
    </div>
  </div>
</template>
    
<script setup name="StoreQualityTags">
/**
 * @component 优秀店铺标签
 */
import { ref, onMounted } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
const { GB_cssRight } = gbCommonInfo || {}
typeof window !== 'undefined' && register()

const emits = defineEmits(['goToStore'])

defineProps({
  storeQualityList: {
    type: Array,
    default: () => []
  }
})

const storeQualitySwiper = ref(null)
const swiperInstance = ref(null)
const isSwiperBeginning = ref(false)
const isSwiperEnd = ref(false)
const isSwiperContentEnd = ref(false) // swiper内容是否一行展示不全

onMounted(() => {
  initSwiper()
})

function initSwiper() {
  const swiperEl = storeQualitySwiper.value
  if (!swiperEl) return
  const swiperParams = {
    direction: 'horizontal',
    spaceBetween: 8,
    slidesPerView: 'auto',
    updateOnImagesReady: true,
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true, // 修改swiper的父元素时，自动初始化swipe
    modules: [Navigation],
    navigation: {
      prevEl: '.j-storeswiper-prev',
      nextEl: '.j-storeswiper-next',
    },
    on: {
      init: (swiper) => {
        isSwiperBeginning.value = swiper.isBeginning
        isSwiperContentEnd.value = swiper.isEnd
      },
      slideChange: (swiper)=>{
        const { isBeginning, isEnd } = swiper || {}
        isSwiperBeginning.value = isBeginning
        isSwiperEnd.value = isEnd
      },
      reachBeginning: ()=>{
        isSwiperBeginning.value = true
        isSwiperEnd.value = false
      },
      reachEnd: () => {
        isSwiperEnd.value = true
        isSwiperBeginning.value = false
      }
    }
  }
  Object.assign(swiperEl, swiperParams)
  swiperEl.initialize()
  swiperInstance.value = swiperEl.swiper
}
function goToStore() {
  emits('goToStore')
}
</script>
    
<style lang="less">
  .store-quality-tags {
    position: relative;
    height: 14px;
    padding-top: 15px;
    padding-bottom: 3px;
    cursor: pointer;
    box-sizing: content-box;
    &__swiper {
      display: flex;
    }
    &__slide {
      width: auto;
    }
    &__item {
      display: flex;
      align-items: center;
      .tags-icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .tags-text {
        font-family: Arial;
        color: #222;
        font-weight: 400;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
      }
    }
    &__empty {
      width: 24px;
      height: 14px;
    }
    &__thumb {
      position: absolute;
      right: 0;
      top: 15px;
      display: flex;
      width: 32px;
      height: 14px;
      justify-content: center;
      background-color: #fff;
      z-index: @zindex-out;
      .thumb-shadow {
        width: 4px;
        height: 14px;
        flex-shrink: 0;
        background: linear-gradient(89deg, rgba(255, 255, 255, 0.00) 1.07%, rgba(0, 0, 0, 0.08) 99.05%);
      }
      .thumb-icon {
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
        &:first-child {
          margin-right: 4px;
        }
      }
    }
  }
</style>
