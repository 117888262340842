<template>
  <div
    v-if="relativeInfo"
    class="shop-entry__entryBox"
    @keyup.esc="escHandle"
  >
    <!-- head title -->
    <!-- inherit product page style -->
    <div
      ref="storeHeadHook"
      v-enterkey
      class="product-intro__brand-head"
      :class="{
        'disabled-fold': disabledFold
      }"
      da-event-click="1-8-6-58"
      tabindex="0"
      :aria-label="title"
      @click="handleFold"
    >
      <span>{{ title }}</span>
      <i
        v-if="!disabledFold"
        class="head-icon"
      >
        <sui_icon_min_16px
          v-if="hitUnfold"
          size="16px"
        />
        <sui_icon_add_16px
          v-else
          size="16px"
        />
      </i>
    </div>

    <!-- fold content -->
    <div
      v-show="hitUnfold"
      class="shop-entry__contentBox"
    >
      <slot name="content"></slot>
      <div v-if="isRecommendSatisfy && isShowRecommend">
        <RecommendList 
          :is-store="isStore"
          :store-info="relativeInfo"
          :recommend-size="recommendSize"
        />
      </div>
    </div>
  </div>
</template>


<script>
import RecommendList from './RecommendList'
import { sui_icon_min_16px, sui_icon_add_16px } from '@shein-aidc/icon-vue3'

export default {
  components: {
    RecommendList,
    sui_icon_min_16px,
    sui_icon_add_16px
  }
}
</script>

<script setup>
/**
 * @component EntryBox 商详页店铺、品牌入口盒子
 * * 依赖商详vuex
 */
import {
  ref,
  unref,
  toRefs,
  computed,
  onMounted,
  onBeforeUnmount,
  reactive,
  watch,
} from 'vue'
import { useStore } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'

/**
 * props
 */
const props = defineProps({
  // 标题
  title: {
    type: String,
    default: ''
  },
  // 标题
  // 盒子承载的店铺或品牌信息，用于埋点和类型获取
  // * relativeInfo。type与vuex中unfold的key对应
  relativeInfo: {
    type: Object,
    default: () => ({})
  },
  // 立即上报一次曝光
  reportImmediately: {
    type: Boolean,
    default: false
  },
  // 额外埋点信息，用于模块曝光
  extAnalysisInfo: {
    type: Object,
    default: () => ({}),
  },
  isShowRecommend: { // 是否在下方展示推荐商品入口
    type: Boolean,
    default: false
  },
  isStore: {
    type: Boolean,
    default: false
  },
  /* 控制两种品牌展示折叠扩展 */
  extendCollapseType: {
    type: String,
    default: ''
  },
  /**
   * 禁用折叠功能，默认全展开
   */
  disabledFold: {
    type: Boolean,
  },
  /**
   * 推荐列表尺寸
   */
  recommendSize: {
    type: String,
    default: ''
  },
  /**
   * 是否单独显示品牌小模块入口
   */
  isAloneStoreBrand: {
    type: Boolean,
    default: false
  },
})
const {
  title,
  relativeInfo,
  reportImmediately,
  extAnalysisInfo,
  isShowRecommend,
  extendCollapseType,
  isStore,
  isAloneStoreBrand
} = toRefs(props)

/**
 * data
 */
const storeHeadHook = ref(null)
const notImmediateReported = ref(false)
const flashEntryMap = reactive({
  'none': 0,
  'showbelt': 1,
  'showflashsale': 2,
  'showflashbutton': 3
})

/**
 * computed
 */
const vuexStore = useStore()
// 商详全局的折叠状态
const unfold = computed(() => vuexStore.state.unfold)

const flashEntryType = computed(() => vuexStore.getters.flashEntryType)
const storeQualityLabel = computed(() => vuexStore.getters.storeQualityLabel)
const isRecommendSatisfy = computed(() => vuexStore.getters.isRecommendSatisfy)

// 块的展开状态
const hitUnfold = computed(() => {
  if (props.disabledFold) return true

  if (unfold.value.length === 0) return false
  return unfold.value?.includes?.(extendCollapseType.value || relativeInfo.value?.type)
})

const storeAtmosphereInfo = computed(() => {
  return vuexStore.getters?.storeAtmosphereInfo
})

/**
 * methods
 */
// esc 关闭
function escHandle() {
  if (unfold.value?.includes(relativeInfo.value?.type)) {
    storeHeadHook.value?.click()
  }
}
// 折叠
function handleFold() {
  if (props.disabledFold) return

  const thisType = extendCollapseType.value || relativeInfo.value?.type
  let updatedArray = [...unfold.value]
  if (updatedArray.includes(thisType)) {
    updatedArray = updatedArray.filter(item => item !== thisType)
  } else {
    updatedArray = [...updatedArray, thisType]
  }
  vuexStore.commit('assignState', {
    unfold: updatedArray
  })
  exposeBrand()
}
// 折叠埋点
function exposeBrand() {
  const { type, id, name, designerId, scId } = relativeInfo.value || {}
  if (!unfold.value?.includes(type)) return
  const { abtAnaInfo, brandInfo, brandLabel } = extAnalysisInfo.value || {}
  // 店铺人气氛围
  let storePopularity = ''
  if(storeAtmosphereInfo.value.length){
    const popTypes = new Set()
    storeAtmosphereInfo.value.forEach(e => {
      if (e.popType) {
        popTypes.add(e.popType)
      }
      storePopularity = Array.from(popTypes).join('\'')
    })
  }else {
    storePopularity = '0'
  }
  const params = {
    brand_type: type,
    brand_code: id,
    page_nm: 'page_goods_detail',
    name,
    designerId,
    scId,
    abtest: abtAnaInfo,
    brand_info: brandInfo,
    brand_label: brandLabel,
    store_qualitylabel: storeQualityLabel.value,
    store_popularity: storePopularity,
    flash_sale_stype: flashEntryMap[flashEntryType.value] || 0,
    is_alone_store_brand: isAloneStoreBrand.value || false
  }
  daEventCenter.triggerNotice({
    daId: '1-8-6-59',
    extraData: params
  })
}
// 即时上报一次
function reportAtOnce() {
  notImmediateReported.value = true
  exposeBrand()
}

watch(reportImmediately, async (newVal, oldVal) => {
  if (notImmediateReported.value || !newVal) return
  if(vuexStore.getters.isRecommendAbt && isShowRecommend.value){
    await vuexStore.dispatch('getRecommendProducts', true)
  }
  reportAtOnce()
}, { immediate: true })

/**
 * hooks
 */
onMounted(() => {
  if(vuexStore.getters.isRecommendAbt && isShowRecommend.value){
    vuexStore.dispatch('getRecommendProducts')
  }
})

onBeforeUnmount(() => {
  // 解除引用
  unref(storeHeadHook)
})
</script>

<style lang="less">
.shop-entry__entryBox {
  display: block;

  .shop-entry__contentBox {
    padding: 15px 0;
    font-size: 12px;
  }
}
</style>
