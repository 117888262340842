import _ from 'lodash'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'

export const cacheRecentViewGoodsData = productIntroData => {
  var products = []
  var goods = _.cloneDeep(productIntroData?.detail || {})
  var isViewed = false

  if (!goods.goods_id) return

  try {
    products = localStorage.getItem('pc_recent_views')
  } catch (e) {
    //
  }
  if (!products) {
    products = []
  } else {
    products = JSON.parse(products)
  }
  products = products.filter(
    item => item.goodsId.productRelationID != goods.productRelationID
  )
  var goodsInfo = {
    mall_code: productIntroData.mallCode ? productIntroData.mallCode : '',
    goodsId: {
      goods_id: goods.goods_id,
      productRelationID: goods.productRelationID,
      goods_sn: goods.goods_sn,
      goods_name: goods.goods_name,
      goods_url_name: goods.goods_url_name,
      cat_id: goods.cat_id,
      salePrice: goods.salePrice,
      retailPrice: goods.retailPrice,
      goods_img: goods.goods_img,
      goods_thumb: goods.goods_thumb,
      promotion: goods.promotion,
      promotionInfo: goods.promotionInfo,
      unit_discount: goods.unit_discount,
      is_on_sale: goods.is_on_sale,
      stock: goods.stock,
    },
    goods_sn: goods.goods_sn,
    goods_url: getGoodsUrl(goods.goods_url_name, goods.goods_id, goods.cat_id),
    goods_id: goods.goods_id,
    goods_name: goods.goods_name,
    goods_url_name: goods.goods_url_name,
    goods_img: goods.goods_img,
    is_on_sale: goods.is_on_sale,
    stock: goods.stock,
    //是否支持选中
    is_select: true,
    // 是否显示删除按钮
    showtrash: true,
    exclusivePromotionPrice: goods.exclusivePromotionPrice || null,
    store_code: goods?.store_code,
    site_from: gbCommonInfo?.SiteUID,
    business_model: goods?.business_model,
  }
  products.forEach((item, index) => {
    if (item.goods_id == goods.goods_id) {
      products[index] = goodsInfo
      isViewed = true
    }
  })
  if (!isViewed) {
    products.unshift(goodsInfo)
    if (products.length > 100) {
      products.pop()
    }
  }
  try {
    localStorage.setItem('pc_recent_views', JSON.stringify(products))
  } catch (e) {
    //
  }
}
