<template>
  <div class="productintro-wraning">
    <!-- 玩具警告语 -->
    <template v-if="showToyWarning">
      <div class="productintro-wraning__title">
        <sui_icon_caution_18px
          class="productintro-wraning__icon"
          name="sui_icon_caution_18px"
          size="14px"
          color="#EF9B00"
          style="vertical-align: text-bottom"
        />
        <span>
          {{ toyWarningWord.warningTitle }}:
          <template v-if="warningTextList && warningTextList.length === 1">{{ warningTextList[0] }}</template>
        </span>
      </div>
      <div
        v-if="warningTextList && warningTextList.length > 1"
        class="productintro-wraning__list"
      >
        <p
          v-for="item in warningTextList"
          :key="item"
        >
          {{ item }}
        </p>
      </div>
    </template>

    <!-- GPSR欧盟责任人 -->
    <div
      v-if="showEuRespPerson"
      class="productintro-wraning__tips"
      :class="{ 'pointer': euRespPerson.isClick }"
      @click="handleDialogOpen"
    >
      <p>{{ euRespPerson.euRespTitle }}</p>
      <sui_icon_more_right_14px_1
        v-if="euRespPerson.isClick"
        class=""
        size="14px"
        :is-rotate="GB_cssRight"
      />
    </div>
    <!-- detail弹窗 -->
    <SDialog
      v-model:visible="show"
      append-to-body
      :show-close="true"
    >
      <template #title> 
        {{ dialogInfo.popUpTitle }} 
      </template>
      <div class="productintro-wraning__dialog">
        <div class="productintro-wraning__dialog-tit">
          {{ dialogInfo.tipsTitle }}
        </div>
        <div class="productintro-wraning__dialog-item">
          {{ dialogInfo.tipsText }}
        </div>
        <div 
          v-if="dialogInfo.nameValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.nameKey }}:</span>
          <span class="value">{{ dialogInfo.nameValue }}</span>
        </div>
        <div 
          v-if="dialogInfo.addressValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.addressKey }}:</span>
          <span class="value">{{ dialogInfo.addressValue }}</span>
        </div>
        <!-- <div class="productintro-wraning__dialog-item">
          <span class="key">{{ dialogInfo.postalKey }}:</span>
          <span class="value">{{ dialogInfo.postalValue }}</span>
        </div> -->
        <div 
          v-if="dialogInfo.telephoneNumberValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.telephoneNumberKey }}:</span>
          <span class="value">{{ dialogInfo.telephoneNumberValue }}</span>
        </div>
        <div 
          v-if="dialogInfo.emailAddressValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.emailAddressKey }}:</span>
          <span class="value">{{ dialogInfo.emailAddressValue }}</span>
        </div>
        <div 
          v-if="dialogInfo.customerServiceValue" 
          class="productintro-wraning__dialog-item"
        >
          <span class="key">{{ dialogInfo.customerServiceKey }}:</span>
          <span class="value">{{ dialogInfo.customerServiceValue }}</span>
        </div>
      </div>
    </SDialog>
  </div>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import { sui_icon_caution_18px, sui_icon_more_right_14px_1 } from '@shein-aidc/icon-vue3'
import { useMapState } from 'public/src/pages/goods_detail_v2/compositions/vuex.composition.js'
import schttp from 'public/src/services/schttp'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  screenAbtTspConfig: {
    type: Object,
    default: () => ({})
  },
})

const { 
  productIntroData,
} = useMapState([
  'productIntroData',
])

const { goods_id } = toRefs(productIntroData?.value?.detail)
const {
  toyWarningWord,
  euRespPerson,
} = toRefs(productIntroData?.value?.productMiscInfo)

const warningTextList = computed(() => toyWarningWord.value?.warningTextList || [])
const goodsdetailscompliance = props.screenAbtTspConfig?.goodsdetailscompliance || {}
const showEuRespPerson = computed(() => goodsdetailscompliance.param?.gpsr_tips === 'on')
const showToyWarning = computed(() => goodsdetailscompliance.param?.toy_warning_tips === 'on' && warningTextList.value.length)
const show = ref(false)

const handleDialogOpen = () => {
  if(!euRespPerson.value?.isClick) return

  getEurPerson()
}

const dialogInfo = ref({})
const getEurPerson = async () => {
  let res = await schttp({
    url: '/product/get_eu_resp_person',
    params: {
      goods_id: goods_id.value
    },
    useBffApi: true,
  })

  dialogInfo.value = res.info
  show.value = true
}
</script>

<style lang="less">
.ellipsis(@clamp: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @clamp;
}
.productintro-wraning {
  font-size: 12px;
  color: #222;
  &__title {
    // display: flex;
    // align-items: center;
    .ellipsis(3);
    gap: 2px;
  }
  &__icon {
    margin-right: 2px;
    flex-shrink: 0;
  }
  &__list {
    p {
      padding-left: 18px;
      line-height: normal;
      .ellipsis(3);
      &::before {
        content: '•';
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
  &__tips {
    color: #666;
    padding-top: 5px;
    display: flex;
    align-items: center;
    gap: 4px;
    p {
      .ellipsis(2)
    }
  }
  .pointer{
    cursor: pointer;
  }
  &__dialog {
    color: #000;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }
  &__dialog-tit {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  &__dialog-item {
    margin-top: 12px;
    word-break: break-word;
  }
}
</style>
