<template>
  <div
    v-expose="{
      id: '1-8-6-257',
      data: rankingAnalysisData,
    }"
    class="product-item__ranking-item"
    :class="`product-item__${rankingoptizAbt} ${rankingAllowJump ? 'allow-jump' : ''}`"
    @click="handleRankingJump"
  >
    <p class="ranking-item__primary-wrapper">
      <img 
        :src="rankingIcon"
        class="ranking-item__img"
      />
      <span class="ranking-item__text">
        <template v-if="rankingoptizAbt === 'new'">
          {{ (language.SHEIN_KEY_PC_32674 || `No.`) + rankingList.score }} 
        </template>
        <template v-if="['plana-1', 'plana-3'].includes(rankingoptizAbt)">
          {{ '#' + rankingList.score }} 
        </template>
        {{ rankingList.rankTypeText }} 
      </span>
    </p>
    <span class="ranking-item__second">
      {{ rankingList.composeIdText }}
    </span>
    <span
      v-if="rankingoptizAbt === 'plana-2'"
      class="ranking-item__score-right"
    >
      {{ (language.SHEIN_KEY_PC_32674 || `No.`) + rankingList.score }} 
    </span>
    <span
      v-if="showRankingRecommendList"
      class="ranking-item__rank-recommend"
    >
      <span
        v-for="(item, index) in rankingRecommendList"
        :key="index"
        class="ranking-item__recommend-item"
      >
        <img
          class="ranking-item__recommend-img"
          :src="item.goods_imgs?.goods_img"
          :alt="item.goods_id"
        />
      </span>
    </span>
    <span
      v-if="rankingAllowJump"
      class="ranking-item__jump"
      :class="`ranking-item__jump__${rankingoptizAbt}`"
    >
      <Icon
        name="sui_icon_more_right_18px"
        size="12px"
        color="#C07900"
        :is-rotate="GB_cssRight"
      />
    </span>
  </div>
</template>
  
<script>
import { Icon } from '@shein-aidc/icon-vue3'
const { GB_cssRight } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter'

export default {
  name: 'ProductItemRankingList',
  components: {
    Icon,
  },
  props: {
    rankingList: {
      type: Object, 
      default: () => ({})
    },
    rankingRecommendList: {
      type: Array, 
      default: () => ([])
    },
    language: {
      type: Object, 
      default: () => ({})
    },
    rankingoptizAbt: {
      type: String, 
      default: () => ''
    },
    rankingAllowJump: {
      type: Boolean, 
      default: false
    },
    getRankingLink: {
      type: Function, 
      default: () => async () => {}
    },
    rankingAnalysisData: {
      type: Object, 
      default: () => ({})
    },
  },
  data() {
    return {
      GB_cssRight,
    }
  },
  computed: {
    rankingIcon() {
      return ['plana-1', 'plana-2', 'plana-3'].includes(this.rankingoptizAbt) ? 'https://img.ltwebstatic.com/images3_ccc/2024/10/14/e4/17288876566be980ba8b6263cfe4d6d817ddb3493b.png' : 'https://img.ltwebstatic.com/images3_ccc/2024/09/11/44/1726040176dbfd0d416b9fca80fe1a267dd1f42fa1.png'
    },
    showRankingRecommendList() {
      return this.rankingRecommendList.length > 0 && this.rankingoptizAbt === 'plana-3'
    },
  },
  methods: {
    async handleRankingJump() {
      if (!this.rankingAllowJump) return

      const url = await this.getRankingLink()
      if (!url) return

      daEventCenter.triggerNotice({
        daId: '1-8-6-258',
        extraData: this.rankingAnalysisData,
      })

      window.location.href = url
    },
  }
}
</script>
<style lang="less">
.product-item {
  &__ranking-item {
    background: #FFF7E2;
    padding: 7px 8px;
    color: #D58700;
    width: 100%;
    font-size: 12px;
    max-width: 100%;
    display: flex;
    align-items: center;
    color: #B4810F;
    margin: 8px 0;
    border-radius: 2px;
    line-height: 1;
    height: 28px;
    .ranking-item__primary-wrapper{
      display: flex;
      align-items: center;
      flex-shrink: 0;
      color: #D58700;
      font-weight: 700;
      font-size: 12px;
      background: none;
      border-radius: 2px; 
      height: 16px;
    }
    .ranking-item__second{
      color: #D58700;
      font-size: 12px;
      flex: 1;
      .text-overflow();
      max-width: 100%;
      margin-left: 8px;
    }
    .ranking-item__img {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
    .ranking-item__text {
      max-width: 100%;
      .text-overflow();
    }

    &.allow-jump {
      cursor: pointer;
    }
  }
  &__plana-1, &__plana-2, &__plana-3 {
    background: #FFF3D6;
    padding: 4px 8px 4px 4px;
    .ranking-item__primary-wrapper{
      height: 20px;
      border-radius: 6px 0px;
      background: linear-gradient(0deg, #D58700 0%, #D58700 100%), linear-gradient(113deg, #F7DD9F -188.01%, #D6862A 85.3%);
      box-shadow: 0px 0.5px 0px 0px rgba(255, 255, 255, 0.20), 0px 0.5px 0.5px 0px rgba(217, 134, 48, 0.10) inset;
      padding: 0 4px;
      color: #FFF;
      font-weight: 700;
      text-transform: capitalize;
    }
    .ranking-item__score-right{
      font-style: italic;
      font-weight: 700;
      text-transform: capitalize;
      color: #D58700;
      margin-left: 8px;
    }
    .ranking-item__rank-recommend{
      padding-left: 16px;
      display: flex;
      align-items: center;
    }
    .ranking-item__recommend-item{
      width: 20px;
      height: 20px;
      display: inline-block;
      border: 1px solid #FFF;
      margin-left: -8px;
      border-radius: 50%;
    }
    .ranking-item__recommend-img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

}

.ranking-item__jump{
  border-radius: 100px;
  border: 0.5px solid #D58700;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* stylelint-disable selector-class-pattern  */
  &__plana-2, &__plana-3 {
    border: none;
    margin-left: 2px;
  }
}
</style>
